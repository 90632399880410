import React from 'react'
import { FieldValues, Path, useController } from 'react-hook-form'
import type { AnyObjectSchema } from 'yup'

import { RadioField, RadioFieldGroup, RadioFieldGroupProps } from '@acre/design-system'
import { FieldBaseProps, useFieldValidationSchema, useFormApi, useFormContext } from '@acre/forms'

import getCommonTranslatedProps from '../../util/getCommonTranslatedProps'
import type { Option } from '../../shared.types'
import isValueDefined from '@acre/forms/src/lib/components/util/isValueDefined'

// TODO: Fix this component
// import ErrorMessageDisplay, { RenderErrorMessageProps } from '../../common/ErrorMessageDisplay'
// import { FormHelperText } from '@mui/material'
// import { ErrorMessage } from '@acre/forms'

export interface RadioFieldGroupRHFProps<T extends FieldValues = FieldValues>
  extends Omit<RadioFieldGroupProps, 'value' | 'onChange' | 'onBlur' | 'inputRef' | 'name'>,
    FieldBaseProps {
  name: Path<T>
  options: Option[]
}

function _RadioFieldGroupRHF<T extends FieldValues = FieldValues, S extends AnyObjectSchema = AnyObjectSchema>(
  props: RadioFieldGroupRHFProps<T>,
) {
  const { name, shouldUnregister = true, options, ...other } = props
  const { control } = useFormContext<T>()
  const {
    field,
    fieldState: { error: fieldStateError },
  } = useController<T>({ name, control, shouldUnregister })
  const fieldSchema = useFieldValidationSchema<T, S>(name)

  const { parseText } = useFormApi()

  const { error, helperText, label } = getCommonTranslatedProps(props, { error: fieldStateError, parseText })

  return (
    <RadioFieldGroup
      {...other}
      onChange={field.onChange}
      value={isValueDefined(field.value) ? field.value : 0}
      onBlur={field.onBlur}
      name={field.name}
      // inputRef={field.ref}
      aria-required={!fieldSchema?.spec?.optional}
      WrapperProps={{ error: Boolean(error), required: !fieldSchema?.spec?.optional }}
      label={label}
      helperText={helperText}
      error={error}
      ErrorTextProps={{ slot: 'div' }}
    >
      {options?.map((item) => (
        <RadioField
          key={item.value.toString()}
          value={item.value}
          label={parseText && typeof item.label === 'string' ? parseText(item.label) : item.label}
        />
      ))}
    </RadioFieldGroup>
  )
}

const RadioFieldGroupRHF = React.memo(_RadioFieldGroupRHF) as typeof _RadioFieldGroupRHF

export default RadioFieldGroupRHF
