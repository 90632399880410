import React from 'react'
import { RadioGroup, RadioGroupProps, styled, unstable_composeClasses } from '@mui/material'
import classnames from 'classnames'

import { ExtendField, FieldProps } from '../Field/Field.types'
import useAriaProps from '../Field/hooks/useAriaProps'
import useFieldSlotProps from '../Field/hooks/useFieldSlotProps'
import FieldWrapper from '../FieldWrapper'
import type { RadioFieldProps, RadioFieldVariant } from '../RadioField'
import { getRadioFieldGroupUtilityClass } from './radioFieldGroupClasses'

export type RadioFieldGroupLayout = 'row' | 'column'

export interface RadioFieldGroupProps extends FieldProps, ExtendField<RadioGroupProps> {
  variant?: RadioFieldVariant
  groupLayout?: RadioFieldGroupLayout
}

const RadioFieldGroupRoot = styled(FieldWrapper, {
  name: 'RadioFieldGroup',
  slot: 'Root',
  overridesResolver: (props, styles) => styles.root,
  shouldForwardProp: (prop) => prop !== 'groupLayout',
})<RadioFieldGroupProps>(({ variant, groupLayout, layout }) => {
  return {
    ...(layout === 'row' && {
      alignItems: 'normal',
    }),

    [`& .${getRadioFieldGroupUtilityClass('radioGroup')}`]: {
      ...(variant === 'outlined' && {
        gap: `0.25rem`,
      }),

      ...(groupLayout === 'row' && {
        flexDirection: 'row',
      }),
    },
  }
})

const RadioFieldGroupRadioGroup = styled(RadioGroup, {
  name: 'RadioFieldGroup',
  slot: 'RadioGroup',
  overridesResolver: (props, styles) => styles.radioGroup,
})``

const useUtilityClasses = (ownerState: Partial<RadioFieldGroupProps>) => {
  const slots = {
    root: ['root', ownerState.layout || 'row', ownerState.size || 'medium'],
    label: ['label'],
    radioGroup: ['radioGroup'],
    radio: ['radio'],
    helperText: ['helperText'],
    errorText: ['errorText'],
  }

  return unstable_composeClasses(slots, getRadioFieldGroupUtilityClass, ownerState.classes)
}

const RadioFieldGroup = (props: RadioFieldGroupProps) => {
  const {
    className,
    classes,
    label,
    children,
    helperText,
    error,
    name,
    layout,
    groupLayout,
    responsive,
    variant = 'standard',
    FormHelperTextProps,
    ErrorTextProps,
    InputLabelProps,
    WrapperProps,
    ...rootProps
  } = props

  const slotClasses = useUtilityClasses({ classes, layout, size: rootProps.size })

  const { fieldAriaProps } = useAriaProps(props)

  const { labelProps, helperTextProps, errorProps } = useFieldSlotProps(slotClasses, {
    ...props,
    FormHelperTextProps,
    ErrorTextProps,
    InputLabelProps,
  })

  return (
    <RadioFieldGroupRoot
      {...WrapperProps}
      className={classnames(slotClasses.root, className)}
      name={name}
      label={label}
      helperText={helperText}
      error={error}
      InputLabelProps={labelProps}
      FormHelperTextProps={helperTextProps}
      ErrorTextProps={errorProps}
      variant={variant}
      layout={layout}
      groupLayout={groupLayout}
      responsive={responsive}
    >
      <RadioFieldGroupRadioGroup {...rootProps} {...fieldAriaProps} className={slotClasses.radioGroup}>
        {React.Children.map(children as React.FunctionComponentElement<RadioFieldProps>[], (child) => {
          if (React.isValidElement(child)) {
            return React.cloneElement<RadioFieldProps>(child, {
              WrapperProps: { className: slotClasses.radio },
              variant,
            })
          }
        })}
      </RadioFieldGroupRadioGroup>
    </RadioFieldGroupRoot>
  )
}

export default React.memo(RadioFieldGroup) as typeof RadioFieldGroup
