import React, { createContext } from 'react'
import type { FieldValues } from 'react-hook-form'

import type { ComponentMapBase } from '../types/schema-base'
import type { CancelHandler } from '../types/types'

export interface FormApiContextType<
  TFieldValues extends FieldValues = FieldValues,
  CM extends Partial<ComponentMapBase> = Partial<ComponentMapBase>,
> {
  componentMap: CM
  errorMode?: 'first' | 'all'
  parseText?: (text: string) => string
  onCancel?: CancelHandler<TFieldValues>
  submitMode?: 'allow' | 'block'
  responsive?: boolean
  debug?: boolean
}

export const FormApiContext = createContext<FormApiContextType>({
  componentMap: {} as ComponentMapBase,
  errorMode: 'first',
  submitMode: 'allow',
  responsive: false,
  debug: false,
})

export const FormApiProvider = FormApiContext.Provider

const useFormApi = () => React.useContext(FormApiContext)

export default useFormApi
