import { GraphQLError } from 'graphql'
import { omit } from 'lodash'

import {
  addCase,
  bulkCreateRemoLeads,
  CaseLoader,
  createCaseFlagReview,
  createReferralCase,
  fetchAllCases,
  fetchCasesForClient,
  fetchVerifications,
  getCaseByVersion,
  getCaseVersions,
  getMiCaseList,
  getReferredCaseList,
  updateCase,
  updateCaseAssignees,
  updateCaseStatus,
} from '../api/case'
import { fetchPropertiesForCase } from '../api/case_properties'
import { addClient, fetchClients, updateClient } from '../api/client'
import { formatFeeAsLuther, updateRequirements } from '../api/common.helpers'
import {
  createDocumentVerification,
  getMiDocumentSummaries,
  getMiDocumentTotals,
  renderTemplate,
} from '../api/document'
import { fetchMortgages, updateMortgage } from '../api/mortgage'
import { addNote } from '../api/notes'
import { fetchOrganisation, OrganisationLoader } from '../api/organisation'
import { addProperty, fetchPropertiesForClients, getProperty, updateProperty } from '../api/property'
import { getProtectionProduct } from '../api/protection_product'
import { UserLoader } from '../api/user'
import {
  addCaseUserProperty,
  ClientLoaderUser,
  fetchPropertyCP,
  getPropertyCP,
  updatePropertyCP,
} from '../api_client_portal'
import { CaseLoaderClient, updateCaseCp } from '../api_client_portal/case'
import { fetchCasesForClient as fetchCasesForClientFromCP } from '../api_client_portal/case'
import {
  addMortgageToCaseCP,
  deleteMortgageCp,
  fetchMortgagesForCaseCP,
  fetchMortgagesForPropertiesCP,
  updateMortgageCP,
} from '../api_client_portal/mortgage'
import {
  CaseCompliance,
  CaseResponse,
  CaseStatus,
  CaseVersion,
  ClientRelationship,
  ClientVersion,
  Maybe,
  MiDocumentTotal,
  Mortgage,
  MortgageReason,
  MortgageStatus,
  NewFee,
  Organisation,
  PropertyVersion,
  ProtectionProduct,
  Relationship,
  RequirementType,
  Resolvers,
  TemplateType,
  User,
  VerificationSourceType,
  VerificationType,
} from '../generated/resolvers'
import { getDirectors } from '../utils/getDirectors'
import { formatMortgage } from '../utils/schemaMapping/mortgage'
import { fetchVersionedCaseChildEntities } from '../utils/versionedCaseChildEntities'
import { createFingerprint } from './Document.helpers'
import { addPropertyOnCase } from './helpers/addPropertyOnCase'
import { propertyCreatedAtComparator, removeDuplicateProperties } from './Property.helpers'
import { GraphqlException } from './util'

const CaseResolver: Resolvers = {
  Query: {
    cases: (_parent, { ids }) => CaseLoader.loadMany(ids || []),
    case: async (_parent, { id }) => {
      CaseLoader.clear(id)
      return await CaseLoader.load(id)
    },
    caseUser: async (_parent, { id }) => {
      CaseLoaderClient.clear(id)
      const user = await CaseLoaderClient.load(id)

      if (!user) {
        throw new GraphQLError(`Client not found for id: ${id}`, {
          extensions: {
            code: 'NOT_FOUND',
          },
        })
      }

      return user
    },
    caseUserSurvey: async (_parent, { id }) => {
      CaseLoaderClient.clear(id)
      const user = await CaseLoaderClient.load(id)

      if (!user) {
        throw new GraphQLError(`Client not found for id: ${id}`, {
          extensions: {
            code: 'NOT_FOUND',
          },
        })
      }

      return user
    },
    allCases: async (_parent, { params }) => {
      const caseResponse = await fetchAllCases(params)

      if (!caseResponse) {
        throw new GraphqlException('Error fetching cases')
      }

      const cases = caseResponse.cases

      return {
        cases,
        next_page: caseResponse.next_page,
      } as CaseResponse
    },
    casesResponse: async (_parent, { organisationId, params }) =>
      fetchAllCases({
        ...params,
        filter_owner_org_id: organisationId,
      }) as CaseResponse,
    clientCases: async (_parent, { clientId, fromClientPortal }) => {
      const response = fromClientPortal
        ? await fetchCasesForClientFromCP(clientId)
        : await fetchCasesForClient(clientId)

      return (response?.filter(Boolean) as CaseVersion[]) || []
    },
    directorClientCases: async (_parent, { clientId, fromClientPortal, deniedAccess }) => {
      let clients: Maybe<ClientVersion> = null

      if (fromClientPortal) {
        clients = (await ClientLoaderUser.load(clientId)) as ClientVersion
      }

      if (!clients) {
        return []
      }

      let relationships: Maybe<Relationship[]>

      const director_ids = [clients].reduce((clientIds: string[], company) => {
        relationships = company.details.relationships

        if (!relationships) return clientIds

        const directors = relationships
          .filter(
            (relationship) =>
              [
                ClientRelationship.DirectorTrustee,
                ClientRelationship.DirectorTrusteeOf,
                ClientRelationship.DirectorTrusteeAndPscOf,
                ClientRelationship.DirectorTrusteeAndPsc,
                ClientRelationship.Psc,
                ClientRelationship.PscOf,
              ].includes(relationship.relationship_type as ClientRelationship) &&
              (deniedAccess === false
                ? relationship.relationship_data_access_permitted === deniedAccess
                : relationship.relationship_data_access_permitted !== false),
          )
          .map((director) => director.relationship_reference!)
        return [...clientIds, ...directors]
      }, [])

      let directorCases = (
        await Promise.all(
          director_ids.map(async (directorId) => {
            const cases = await fetchCasesForClientFromCP(directorId)
            return cases.filter(Boolean)
          }),
        )
      ).flat()

      return directorCases || []
    },
    allClientCases: async (_parent, { clientId, fromClientPortal, deniedAccess }) => {
      // Get client data first to check relationships and permissions
      let clients: Maybe<ClientVersion> = null
      if (fromClientPortal) {
        clients = (await ClientLoaderUser.load(clientId)) as ClientVersion
      }

      // Get direct client cases
      const clientCases = fromClientPortal
        ? await fetchCasesForClientFromCP(clientId)
        : await fetchCasesForClient(clientId)

      // If no client found, return filtered client cases
      if (!clients) {
        return (clientCases?.filter(Boolean) as CaseVersion[]) || []
      }

      // Get relationships for permission checking
      const relationships = clients.details.relationships
      if (!relationships) {
        return (clientCases?.filter(Boolean) as CaseVersion[]) || []
      }

      // Get director IDs based on relationship types and permissions
      const director_ids = relationships
        .filter(
          (relationship) =>
            [
              ClientRelationship.DirectorTrustee,
              ClientRelationship.DirectorTrusteeOf,
              ClientRelationship.DirectorTrusteeAndPscOf,
              ClientRelationship.DirectorTrusteeAndPsc,
              ClientRelationship.Psc,
              ClientRelationship.PscOf,
            ].includes(relationship.relationship_type as ClientRelationship) &&
            (deniedAccess === true
              ? relationship.relationship_data_access_permitted === false
              : relationship.relationship_data_access_permitted !== false),
        )
        .map((director) => director.relationship_reference!)

      // Fetch all director cases
      const directorCases = (
        await Promise.all(
          director_ids.map(async (directorId) => {
            const cases = await fetchCasesForClientFromCP(directorId)
            return cases.filter(Boolean)
          }),
        )
      ).flat()

      // Filter client cases based on deniedAccess flag
      const filteredClientCases = clientCases?.filter(() => {
        const clientRelationship = relationships.find(
          (rel) => rel.relationship_reference === clientId
        )
        if (!clientRelationship) return true // Keep if no relationship found
        return deniedAccess === true
          ? clientRelationship.relationship_data_access_permitted === false
          : clientRelationship.relationship_data_access_permitted !== false
      })

      // Combine filtered client and director cases, remove duplicates by case ID
      const allCases = [...(filteredClientCases || []), ...directorCases]
      const uniqueCases = Array.from(
        new Map(allCases.map(caseItem => [caseItem.id, caseItem])).values()
      )

      return uniqueCases as CaseVersion[]
    },

    complianceCases: async (_parent, { organisationId, params }) => {
      const caseResponse = await fetchAllCases({
        ...params,
        filter_regulated_by_org_id: organisationId,
      })

      if (!caseResponse) {
        throw new GraphqlException('Error fetching cases')
      }

      const organisation = await fetchOrganisation(organisationId)
      const cases = caseResponse.cases

      return {
        cases,
        next_page: caseResponse.next_page,
        organisation_name: organisation ? organisation.name : undefined,
      } as CaseCompliance
    },
    getMiCaseList: async (_parent, { input }) => {
      const results = await getMiCaseList(input)

      return {
        ...results,
        cases: results.cases.map((caseDetails) => ({
          ...caseDetails,
          introducer:
            caseDetails.introducer &&
            ({
              ...caseDetails.introducer,
              id: caseDetails.introducer_id,
            } as Organisation),
        })),
      }
    },
    referredCaseList: async (_parent, { input }) => await getReferredCaseList(input),
    caseVersions: async (_parent, { id }) => await getCaseVersions(id),
    caseVersion: async (_parent, { id, version }) => await getCaseByVersion(id, version),
  },
  Case: {
    clients: async (parent, { fromClientPortal, withFullDetails }) => {
      const fullDetails = withFullDetails ?? true

      const { client_ids, status, transition_client_versions } = parent

      if (!client_ids) {
        return []
      }

      let clients: Maybe<ClientVersion[]> = null

      if (fromClientPortal) {
        clients = (await ClientLoaderUser.loadMany(client_ids)).filter((client) => client !== null) as ClientVersion[]
      } else if (!status) {
        return []
      } else {
        if (transition_client_versions?.length) {
          clients = await fetchVersionedCaseChildEntities('client', {
            status,
            transitionVersions: transition_client_versions,
          })
        }
        if (!clients) {
          clients = await fetchClients({ client_ids, client_details: fullDetails })
        }
      }

      if (!clients) {
        return []
      }

      const director_ids = getDirectors(clients)
      const filteredDirectorIds = director_ids.filter((id) => !client_ids.includes(id))

      let directorClients: Maybe<ClientVersion[]> = null

      if (fromClientPortal && filteredDirectorIds.length) {
        directorClients = (await ClientLoaderUser.loadMany(filteredDirectorIds)) as ClientVersion[]
      } else if (filteredDirectorIds.length) {
        directorClients = await fetchClients({ client_ids: filteredDirectorIds })
      }

      if (directorClients) {
        clients = clients.concat(directorClients)
      }

      return clients
    },
    clientsUser: async (parent) => {
      const { client_ids } = parent

      if (!client_ids) {
        return []
      }

      const caseClients = await ClientLoaderUser.loadMany(client_ids)

      const cleanCaseClients = caseClients.filter((client) => client !== null) as ClientVersion[]

      const director_ids = getDirectors(cleanCaseClients)

      const clientsUsers = await ClientLoaderUser.loadMany([...client_ids, ...director_ids])

      const filteredClients = clientsUsers.filter((client) => client !== null) as ClientVersion[]

      return filteredClients
    },
    owner_organisation: async ({ owner_user_id, owner_organisation_id }) => {
      let orgId

      if (owner_user_id) {
        const user = (await UserLoader.load(owner_user_id)) as User
        orgId = user.organisation_id
      } else if (owner_organisation_id) {
        orgId = owner_organisation_id
      }

      if (!owner_organisation_id && !owner_user_id) {
        throw new GraphQLError('Case owner not found')
      }

      const result = await OrganisationLoader.load(orgId as string)

      if (result instanceof GraphQLError) {
        throw result
      }

      return result
    },
    owner: async ({ owner_user_id, status }) => {
      // Lead Cases do not currently have owners
      if (status === CaseStatus.Lead || status === CaseStatus.NotProceeding) {
        return null
      }

      if (!owner_user_id) {
        throw new GraphQLError('Case owner not found')
      }

      const result = await UserLoader.load(owner_user_id)

      if (result instanceof GraphQLError) {
        throw result
      }

      return result
    },
    mortgages: async ({ id: case_id, status, transition_mortgage_versions }) => {
      const versionEntities = await fetchVersionedCaseChildEntities('mortgage', {
        status: status || undefined,
        transitionVersions: transition_mortgage_versions,
      })

      if (versionEntities) {
        return versionEntities
      }

      const mortgages = await fetchMortgages({ filter_case_id: case_id })

      return mortgages || ([] as Mortgage[])
    },
    mortgages_cp: async ({ id: case_id }) => {
      const data = await fetchMortgagesForCaseCP(case_id)
      const mortgages = data?.mortgages?.map((mortgage) => formatMortgage(mortgage)) || []
      return mortgages
    },

    protection_products: async ({ id, status, transition_protection_versions }) => {
      const versionEntities = await fetchVersionedCaseChildEntities('protection', {
        status: status || undefined,
        transitionVersions: transition_protection_versions,
      })

      if (versionEntities) {
        return versionEntities || ([] as ProtectionProduct[])
      }

      const params = { protection_product_details: true, filter_case_ids: [id] }
      const { protection_products } = await getProtectionProduct(params)

      return (protection_products as ProtectionProduct[]) || ([] as ProtectionProduct[])
    },
    // documents: async ({ id }) => {
    //   const params = { filter_case_id: id }
    //   const { documents } = await getDocumentSummaries(params)
    //   return documents as Document[]
    // },
    miDocuments: async ({ id }, { filters, fromClientPortal }) => {
      return id ? await getMiDocumentSummaries('case', id, filters, fromClientPortal) : null
    },
    document_totals: async ({ id }, { filters }) => {
      const documentTotals = await getMiDocumentTotals('case', id, filters)

      return documentTotals as MiDocumentTotal[]
    },
    new_property: async ({
      preference_target_property,
      transition_property_versions,
      transition_mortgage_versions,
      status,
      preference_mortgage_reason,
    }) => {
      if (status && preference_target_property) {
        let caseStatus = status
        // Previously, a backend bug caused GI cases to miss creating a transition property version object when the case status moved to Complete.
        // This led to the frontend showing empty property data upon case completion.
        // Although the backend bug is now fixed, many legacy cases would need migration.
        // CG confirmed it's safe for the frontend to use the ApplicationSubmitted property version if the Complete version is missing.
        // This code implements a workaround for that issue.
        if (
          preference_mortgage_reason === MortgageReason.ReasonGeneralInsurance &&
          caseStatus === CaseStatus.Complete
        ) {
          const hasCaseCompleteTransition = transition_property_versions?.some(
            (propertyVersion) => propertyVersion.transition_to === CaseStatus.Complete,
          )

          if (!hasCaseCompleteTransition) {
            caseStatus = CaseStatus.ApplicationSubmitted
          }
        }
        const property = await fetchVersionedCaseChildEntities('property', {
          status: caseStatus,
          transitionVersions: transition_property_versions,
          entityId: preference_target_property,
        })

        if (!property?.length) {
          return null
        }
        return {
          ...property[0],
          details: {
            ...property[0].details,
            case_status: status,
            transition_mortgage_versions,
          },
        }
      }

      return null
    },
    new_property_cp: async ({ preference_target_property }) =>
      preference_target_property ? await fetchPropertyCP(preference_target_property) : null,
    remortgage_property: async ({
      preference_related_property_sale,
      transition_property_versions,
      transition_mortgage_versions,
      status,
    }) => {
      if (status && preference_related_property_sale) {
        const property = await fetchVersionedCaseChildEntities('property', {
          status,
          transitionVersions: transition_property_versions,
          entityId: preference_related_property_sale,
        })

        if (!property?.length) {
          return null
        }

        return {
          ...property[0],
          details: {
            ...property[0].details,
            case_status: status,
            transition_mortgage_versions,
          },
        }
      }

      return null
    },
    remortgage_property_cp: async ({ preference_related_property_sale }) =>
      preference_related_property_sale
        ? await fetchPropertyCP(preference_related_property_sale)
        : Promise.resolve(null),
    verifications: async (parent, { show_pass, show_documents }) => {
      const { status, id } = parent

      if (status === CaseStatus.Lead || !id) {
        return null
      }

      return await fetchVerifications(id, show_pass, show_documents)
    },
    regulated_by: async ({ regulated_by }) => Promise.resolve(regulated_by || null),
    property_portfolio: async (parent, { includeDirectorsProperties = false }) => {
      const { client_ids, status, transition_mortgage_versions } = parent
      const properties = await fetchPropertiesForCase({ caseDetails: parent })

      const caseProperties = properties.map((property) => ({
        ...property,
        details: { ...property.details, case_status: status, transition_mortgage_versions },
      }))

      if (!client_ids) {
        return caseProperties
      }

      const propertyIds = caseProperties?.map((p) => p.property_id)
      const filteredProperties = caseProperties.filter(Boolean) as PropertyVersion[]

      // fetch director properties if needed
      let director_ids: string[] = []
      if (includeDirectorsProperties) {
        const caseClients = await fetchClients({ client_ids })
        const cleanCaseClients = caseClients.filter((client) => client !== null) as ClientVersion[]
        director_ids = getDirectors(cleanCaseClients)
      }

      const clientProperties =
        (await fetchPropertiesForClients({
          clientIds: [...client_ids, ...director_ids],
          queryParams: { property_details: true },
          fetchAllPropertiesForClient: true,
        })) || []

      clientProperties.forEach((clientProperty) => {
        if (clientProperty && !propertyIds.includes(clientProperty.id)) {
          filteredProperties.push(clientProperty)
          propertyIds.push(clientProperty.id)
        }
      })

      const deduped = removeDuplicateProperties(filteredProperties)
      const sortedByDate = deduped.sort(propertyCreatedAtComparator)

      return sortedByDate
    },
  },
  MICase: {
    advisor: async ({ advisor_id }) => {
      if (!advisor_id) {
        return null
      }

      const result = await UserLoader.load(advisor_id)

      if (result instanceof GraphQLError) {
        throw result
      }

      return result
    },
  },
  Mutation: {
    addCase: (_parent, { input }) => addCase(input),
    createReferralCase: (_parent, { input }) => createReferralCase(input),
    addCompanyAndUpdateCase: async (_parent, { caseId, organisationId, input }) => {
      // creating a company on a case:
      // a company has to be created with initial data
      // required. Then, in order to add further details to
      // the company, we need to confirm the verbal idd
      // rest of the client information can be added
      // and then the case can be updated with all the currently existing
      // client ids and the newly created company id
      const { is_natural_person, organisation_name, organisation_sic, relationships, ...companyDetails } = input

      const companyToCreate = {
        is_natural_person,
        organisation_name,
        organisation_sic,
      }

      // create client/company
      const client = await addClient(companyToCreate)

      if (!client) {
        throw new GraphqlException('Company was not created')
      }

      // create verbal idd for company
      const { document: renderedDocument } = await renderTemplate({
        template_name: TemplateType.VerbalIdd,
        store_document: true,
        client_ids: [client.id],
        organisation_ids: organisationId ? [organisationId] : [],
      })

      if (!renderedDocument) {
        throw new GraphqlException('Company VerbalIDD not generated')
      }

      // Document verifications require a unique hash
      const fingerprint = await createFingerprint()
      const body = {
        document_signature: renderedDocument.document_signature,
        client_id: client.id,
        verification_type: VerificationType.VerbalAcceptance,
        source: VerificationSourceType.UserOnBehalfOfClient,
        verification_fingerprint_browser_id: fingerprint,
      }
      const { verification } = await createDocumentVerification(renderedDocument.document_id!, body)

      if (!verification) {
        throw new GraphqlException('Company VerbalIDD not verified')
      }

      // update company with information which required
      // verbal idd to be confirmed
      const updatedClient = await updateClient(client.id, {
        relationships,
        ...companyDetails,
      })

      if (!updatedClient) {
        throw new GraphqlException(
          `Company additional details not updated with: ${JSON.stringify({
            ...companyDetails,
          })}`,
        )
      }

      // update case with newly created company's id
      if (!caseId) {
        throw new GraphqlException('Case id required to update case')
      }
      const fetchedCase = await CaseLoader.load(caseId)

      if (!fetchedCase || !fetchedCase.details) {
        throw new GraphqlException('Case not found with given id, required to update case')
      }

      // Clients on the case
      const caseClientIds = fetchedCase.details.client_ids ? fetchedCase.details.client_ids : []

      // Clients on the case who have not been made company directors
      // as on BTL case company becomes the client
      // and company info is now mandatory to be filled instead of natural client info
      const caseClientsNotDirectors = caseClientIds.filter((clientId) => {
        const clientRelationship = relationships?.find(
          (relationship) => relationship.relationship_reference === clientId,
        )

        return !clientRelationship
      })

      const updatedCase = await updateCase(
        {
          client_ids: [...caseClientsNotDirectors, client.id],
          // We want to automatically update this value to true, because we're
          // adding a company, we assume the purchase is being made through it
          btl_through_company: true,
        },
        caseId,
      )

      if (!updatedCase) {
        throw new GraphqlException('Case client ids not updated with company id')
      }

      return updatedCase
    },
    createCaseFlagReview: async (_parent, { input }) => {
      await createCaseFlagReview(input)
      // TODO: FRON-1417 change any types
      // we currently have no response from create case flag review endpoint
      return await CaseLoader.clear(input.case_id).load(input.case_id)
    },
    updateCasePreferences: (_parent, { input, id }) => {
      return updateCase(input, id)
    },
    updateCaseFees: async (_parent, { input, id }) => {
      // Organisation and case fees are some of the only examples of currencies
      // stored as pounds rather than pence
      const fees = input?.map((fee) => formatFeeAsLuther(fee as NewFee))
      const response = await updateCase({ fees }, id)
      // after mutation we want to clear the loader as old cache are invalid
      CaseLoader.clear(id)
      return response
    },
    updateCaseRequirements: async (_parent, { input, id }) => {
      //this resolver has to be done this way as we cannot handle null values being sent to BE
      //see RD-14830,RD-14843,RD-17140
      const { protection, selectedID, requirement, requirement_type } = input
      const { requirements } = protection || {}

      if (requirement_type === RequirementType.IncomeProtection) {
        const { income_protection } = requirements || {}
        const updatedRequirements =
          requirement &&
          updateRequirements(income_protection || [], requirement, requirement_type, selectedID)

        const udpatedInput = {
          protection: {
            ...protection,
            requirements: {
              ...requirements,
              income_protection: updatedRequirements,
            },
          },
        }

        return updateCase(udpatedInput, id)
      } else if (requirement_type === RequirementType.LifeAndCriticalIllness) {
        const { life_and_critical_illness } = requirements || {}
        const updatedRequirements =
          requirement &&
          updateRequirements(life_and_critical_illness || [], requirement, requirement_type, selectedID)

        const udpatedInput = {
          protection: {
            ...protection,
            requirements: {
              ...requirements,
              life_and_critical_illness: updatedRequirements,
            },
          },
        }

        return updateCase(udpatedInput, id)
      } else if (requirement_type === RequirementType.PrivateMedicalInsurance) {
        const { private_medical_insurance } = requirements || {}
        const updatedRequirements =
          requirement &&
          updateRequirements(private_medical_insurance || [], requirement, requirement_type, selectedID)

        const udpatedInput = {
          protection: {
            ...protection,
            requirements: {
              ...requirements,
              private_medical_insurance: updatedRequirements,
            },
          },
        }

        return updateCase(udpatedInput, id)
      } else if (requirement_type === RequirementType.FamilyIncomeBenefits) {
        const { family_income_benefits } = requirements || {}
        const updatedRequirements =
          requirement &&
          updateRequirements(family_income_benefits || [], requirement, requirement_type, selectedID)

        const udpatedInput = {
          protection: {
            ...protection,
            requirements: {
              ...requirements,
              family_income_benefits: updatedRequirements,
            },
          },
        }

        return updateCase(udpatedInput, id)
      } else if (requirement_type === RequirementType.WholeOfLife) {
        const { whole_of_life } = requirements || {}
        const updatedRequirements =
          requirement && updateRequirements(whole_of_life || [], requirement, requirement_type, selectedID)

        const udpatedInput = {
          protection: {
            ...protection,
            requirements: {
              ...requirements,
              whole_of_life: updatedRequirements,
            },
          },
        }

        return updateCase(udpatedInput, id)
      }
    },
    updateCase: (_parent, { input, id }) => updateCase(input, id),
    updateCaseAssignees: (_parent, { input }) => input && updateCaseAssignees(input),
    bulkCreateRemoLeads: (_parent, { input }) => input && bulkCreateRemoLeads(input),
    updateCaseCp: (_parent, { input, id }) => {

      return updateCaseCp(input, id)
    },

    deleteCaseProperty: async (_parent, { reasons, caseId, propertyId, propertyType, deleteFromClient }) => {
      const updatedCase = await updateCase(
        {
          change_of_preference_reason: reasons,
          [propertyType]: null,
        },
        caseId,
      )

      if (deleteFromClient) {
        await updateProperty(
          {
            registered_owners_details: null,
          },
          propertyId,
        )
      }

      return updatedCase
    },
    deleteCasePropertyCp: async (_parent, { reasons, caseId, propertyId, propertyType, deleteFromClient }) => {
      const updatedCase = await updateCaseCp(
        {
          change_of_preference_reason: reasons,
          [propertyType]: null,
        },
        caseId,
      )

      const { mortgages } = await fetchMortgagesForPropertiesCP(propertyId)

      if (mortgages?.length) {
        // also delete all associated mortgages and mortgage products
        await Promise.all(
          mortgages.map(({ mortgage_id }) => {
            if (mortgage_id) {
              return deleteMortgageCp(mortgage_id, caseId)
            }
            return Promise.resolve()
          }),
        )
      }

      //Remove deleted property from property_secured_ids in mortgage
      if (propertyType === 'preference_target_property') {
        const { mortgages: caseMortgages } = await fetchMortgagesForCaseCP(caseId)

        if (caseMortgages?.length) {
          const selectedAndProposedMortgages = caseMortgages.filter(
            ({ status }) =>
              status === MortgageStatus.StatusSelected ||
              status === MortgageStatus.StatusProposed ||
              status === MortgageStatus.StatusLenderProposed,
          )

          if (selectedAndProposedMortgages.length !== 0) {
            await Promise.all(
              selectedAndProposedMortgages.map(({ mortgage_id, property_secured_ids }) => {
                const propertiesWithoutDeletedProperty = property_secured_ids?.filter(
                  (property) => property !== propertyId,
                )

                if (mortgage_id && propertiesWithoutDeletedProperty) {
                  return updateMortgageCP(mortgage_id, {
                    property_secured_ids: propertiesWithoutDeletedProperty,
                  })
                }
              }),
            )
          }
        }
      }

      if (deleteFromClient) {
        await updatePropertyCP(
          {
            registered_owners_details: null,
          },
          propertyId,
        )
      }

      return updatedCase
    },

    addExistingPropertyToCase: async (_parent, { propertyId, caseId, propertyType }) => {
      const {
        details: { change_of_preference_reason },
      } = await CaseLoader.load(caseId)

      const caseInput = {
        [propertyType]: propertyId,
        change_of_preference_reason: change_of_preference_reason ? [...change_of_preference_reason] : [],
      }
      const updatedCase = await updateCase(caseInput, caseId)
      return updatedCase
    },
    addCaseProperty: async (_parent, { input, caseId, propertyType }) =>
      addPropertyOnCase({
        input,
        caseId,
        propertyType,
        CaseLoader,
        addProperty,
        getProperty,
        updateProperty,
        fetchMortgages,
        updateCase,
        updateMortgage,
      }),
    addCasePropertyCp: async (_parent, { input, caseId, propertyType }) =>
      addPropertyOnCase({
        input,
        caseId,
        propertyType,
        CaseLoader: CaseLoaderClient,
        addProperty: addCaseUserProperty,
        getProperty: getPropertyCP,
        updateProperty: updatePropertyCP,
        fetchMortgagesForCase: fetchMortgagesForCaseCP,
        updateCase: updateCaseCp,
        updateMortgage: updateMortgageCP,
        addMortgageToCase: addMortgageToCaseCP,
      }),
    updateCaseStatus: (_parent, { input, id }) => updateCaseStatus(id, input),
    updateCaseSuitability: (_parent, { input, id }) => {
      const caseInput = { ...omit(input, ['mortgages']) }
      return updateCase(caseInput, id)
    },
    updateCaseAndAddNote: async (_parent, { id, caseInput, noteInput, ...noteRest }) => {
      const caseResponse = await updateCase(caseInput, id)

      if (!caseResponse) {
        throw new GraphQLError('Case not updated', { extensions: { code: 'CASE_NOT_UPDATED' } })
      }

      let noteResponse

      if (noteInput) {
        noteResponse = await addNote({ input: noteInput, ...noteRest })

        if (!noteResponse) {
          throw new GraphQLError('Note not created', {
            extensions: { code: 'NOTE_NOT_CREATED' },
          })
        }
      }

      return { case: caseResponse, note: noteResponse }
    },
  },
}

export default CaseResolver
