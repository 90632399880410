import React from 'react'
import { styled, unstable_composeClasses } from '@mui/material'
import classnames from 'classnames'
import { type FieldValues, useFormState } from 'react-hook-form'

import { ButtonNewest as Button, ButtonNewestProps as ButtonProps } from '@acre/design-system'
import { useFormApi, useFormContext } from '@acre/forms'

import { getFormSubmitActionUtilityClass } from './formSubmitActionClasses'
import { differenceInObjects2 } from '../../../../../utils/src/differenceInObjects'

export interface FormSubmitActionProps extends ButtonProps {}

const FormSubmitActionRoot = styled(Button, {
  name: 'FormSubmitAction',
  slot: 'Root',
  overridesResolver: (props, styles) => styles.root,
})``

const useUtilityClasses = (ownerState: Partial<FormSubmitActionProps>) => {
  const slots = {
    root: ['root'],
  }

  return unstable_composeClasses(slots, getFormSubmitActionUtilityClass, ownerState.classes)
}

const _FormSubmitAction = (props: FormSubmitActionProps) => {
  const { className, classes, disabled, size = 'large', ...rootProps } = props

  const slotClasses = useUtilityClasses({ classes })

  const { control, getValues, formState } = useFormContext()

  const { submitMode, debug } = useFormApi()

  const { isValid, isSubmitting, isDirty } = useFormState({ control })

  const isDisabled = !isDirty || Boolean(disabled) || (submitMode === 'block' && !isValid)

  if (debug) {
    console.log('[FormSubmitAction] values:', getValues())

    let diff: FieldValues | undefined

    if (isDisabled) {
      let reason = 'unknown'

      if (!isDirty) {
        reason = 'form is not dirty'
      } else if (disabled) {
        reason = 'disabled prop passed from parent'
      } else if (submitMode === 'block' && !isValid) {
        reason = 'submitMode is block and form is not valid'
      }

      console.log(`[FormSubmitAction] button disabled | reason: ${reason}`)
    } else {
      let reason = 'unknown'

      if (isDirty) {
        reason = 'form is dirty'
        diff = formState.defaultValues && differenceInObjects2(formState.defaultValues, getValues())
      } else if (submitMode === 'allow') {
        reason = 'submitMode is allow'
      }

      console.log(`[FormSubmitAction] button enabled | reason: ${reason}`)

      if (diff) {
        console.log('[FormSubmitAction] diff:', diff)
      }
    }
  }

  return (
    <FormSubmitActionRoot
      {...rootProps}
      disabled={isDisabled}
      loading={isSubmitting}
      type="submit"
      size={size}
      className={classnames(slotClasses.root, className)}
    />
  )
}

const FormSubmitAction = React.memo(_FormSubmitAction) as typeof _FormSubmitAction

export default FormSubmitAction
