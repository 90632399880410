import React from 'react'
import { FieldValues, Path, useController } from 'react-hook-form'

import { DisplayField, DisplayFieldProps } from '@acre/design-system'
// import { RenderErrorMessage } from '../../common/ErrorMessageDisplay'
import { FieldBaseProps, useFormApi, useFormContext } from '@acre/forms'

import getCommonTranslatedProps from '../../util/getCommonTranslatedProps'
// import { ErrorMessage } from '@acre/forms'

export interface DisplayFieldRHFProps<T extends FieldValues = FieldValues> extends FieldBaseProps, DisplayFieldProps {
  name: Path<T>
}

function _DisplayFieldRHF<T extends FieldValues = FieldValues>(props: DisplayFieldRHFProps<T>) {
  const { name, shouldUnregister = true, ...other } = props

  const { control } = useFormContext<T>()
  const {
    field,
    fieldState: { error: fieldError },
  } = useController<T>({ name, control, shouldUnregister })

  const { parseText } = useFormApi()

  const translatedProps = getCommonTranslatedProps(props, { error: fieldError, parseText })

  return (
    <DisplayField
      {...other}
      {...translatedProps}
      value={field.value ?? ''}
      name={field.name}
      // Return to this error message when ErrorMessage styling is updated
      // error={<ErrorMessage render={RenderErrorMessage} name={name} />}
      ErrorTextProps={{ slot: 'div' }}
    />
  )
}

const DisplayFieldRHF = React.memo(_DisplayFieldRHF) as typeof _DisplayFieldRHF

export default DisplayFieldRHF
