import { useMemo } from 'react'
import type { Path, FieldValues, ArrayPath } from 'react-hook-form'
import { useFormContext, useWatch } from 'react-hook-form'

import useVisibilitySchema from './useVisibilitySchema'
import useFindSchemaDependencyNames from './useFindSchemaDependencyNames'

export interface UseCheckVisibilityOnDependencyChangeProps<T extends FieldValues> {
  name: Path<T> | ArrayPath<T>
}

/**
 * useCheckVisibilityOnDependencyChange
 *
 * Trigger re-render when visibility check dependencies change their values.
 * Sets visible boolean based on visibility check result.
 */
const useCheckVisibilityOnDependencyChange = <T extends FieldValues>({
  name,
}: UseCheckVisibilityOnDependencyChangeProps<T>) => {
  const { control, getValues } = useFormContext<T>()
  const visibilitySchema = useVisibilitySchema()
  const watchNames = useFindSchemaDependencyNames({ name })

  if (!watchNames?.length) {
    console.warn('calling watch with no dependencies')
  }

  const value = useWatch<T>({
    control,
    name: watchNames as Path<T>,
    exact: true,
  })

  const visible = useMemo(() => {
    if (visibilitySchema) {
      try {
        visibilitySchema.validateSyncAt(name, getValues())
        return true
      } catch (e: unknown) {
        return false
      }
    }

    return true
    // INTENTIONAL: do not update these deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return { visible }
}

export default useCheckVisibilityOnDependencyChange
