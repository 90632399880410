import React from 'react'
import { capitalize, FormControlLabel, getTypographyUtilityClass, styled, unstable_composeClasses } from '@mui/material'
import classnames from 'classnames'

import type { SimpleFieldWrapperProps } from '../Field/Field.types'
import { getSimpleFieldWrapperUtilityClass } from './simpleFieldWrapperClasses'

const SimpleFieldWrapperRoot = styled(FormControlLabel, {
  name: 'SimpleFieldWrapper',
  slot: 'Root',
  overridesResolver: (props, styles) => [
    styles.root,
    styles[`size${capitalize(props.size || 'medium')}`],
    props.error && styles.error,
  ],
  shouldForwardProp: (prop) => prop !== 'size' && prop !== 'variant' && prop !== 'responsive',
})<SimpleFieldWrapperProps>(({ theme, size, error }) => ({
  marginRight: 0,

  [`& .${getTypographyUtilityClass('root')}`]: {
    ...(error && {
      color: theme.palette.error.main,
    }),

    ...(size === 'large' && {
      fontSize: theme.typography.pxToRem(14),

      '& label': {
        fontSize: theme.typography.pxToRem(14),
      },
    }),
  },
}))

const useUtilityClasses = (ownerState: Partial<SimpleFieldWrapperProps>) => {
  const slots = {
    root: ['root', `size${capitalize(ownerState.size || 'medium')}`, ownerState.error && 'error'],
  }

  return unstable_composeClasses(slots, getSimpleFieldWrapperUtilityClass, ownerState.classes)
}

const SimpleFieldWrapper = (props: SimpleFieldWrapperProps) => {
  const { className, classes, size, slotProps, error, LabelProps, ...rootProps } = props

  const slotClasses = useUtilityClasses({ classes, size, error })

  return (
    <SimpleFieldWrapperRoot
      {...rootProps}
      size={size}
      className={classnames(slotClasses.root, className)}
      slotProps={{
        ...slotProps,
        typography: { ...slotProps?.typography, ...LabelProps, variant: 'body2' },
      }}
    />
  )
}

export default React.memo(SimpleFieldWrapper) as typeof SimpleFieldWrapper
