import React from 'react'
import type { FieldValues, Path, ArrayPath } from 'react-hook-form'
import type { AnyObjectSchema } from 'yup'

import useFieldValidationSchema from '../hooks/useFieldValidationSchema'
import useFieldVisibilitySchema from '../hooks/useFieldVisibilitySchema'
import useFormApi from '../hooks/useFormApi'
import type { FieldBaseProps, FieldTypes } from '../types/types'
import CheckVisibilityOnDependencyChange from './util/CheckVisibilityOnDependencyChange'
import RevalidateOnDependencyChange from './util/RevalidateOnDependencyChange'

export type FieldProps<
  TFieldType extends keyof FieldTypes,
  TFieldProps extends Record<string, unknown>,
  TFieldValues extends FieldValues,
> = FieldBaseProps &
  TFieldProps & {
    name: Path<TFieldValues> | ArrayPath<TFieldValues>
    label: string | React.ReactNode
    type: TFieldType
  }

function _Field<
  TFieldType extends keyof FieldTypes,
  TFieldProps extends Record<string, unknown>,
  TValidationSchema extends AnyObjectSchema,
  TFieldValues extends FieldValues,
>({ name, type, responsive: responsiveFieldProp, ...fieldProps }: FieldProps<TFieldType, TFieldProps, TFieldValues>) {
  const { componentMap, responsive: responsiveFormProp } = useFormApi()
  const innerSchema = useFieldValidationSchema<TFieldValues, TValidationSchema>(name)

  if (!innerSchema) {
    console.warn(`No validation schema defined for ${name}`)
  }

  const innerVisibilitySchema = useFieldVisibilitySchema<TFieldValues, TValidationSchema>(name)

  const Cmp = componentMap[type] as React.ComponentType<TFieldProps>

  if (!Cmp) {
    console.error(`Component not found for type: ${type}`)
    return null
  }

  const render = []

  if (innerSchema?.deps?.length) {
    render.push(
      <RevalidateOnDependencyChange<TFieldValues>
        key={0}
        name={name}
        watch={innerSchema.deps as Path<TFieldValues>[]}
      />,
    )
  }

  const responsive = responsiveFieldProp ?? responsiveFormProp

  render.push(<Cmp {...(fieldProps as TFieldProps)} key={1} name={name} responsive={responsive} />)

  if (innerVisibilitySchema?.deps?.length) {
    return <CheckVisibilityOnDependencyChange<TFieldValues> name={name}>{render}</CheckVisibilityOnDependencyChange>
  }

  return <>{render}</>
}

const Field = React.memo(_Field) as typeof _Field

export default Field
