import React, { useCallback } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box } from '@mui/material'
import { DefaultValues, FieldValues, FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import type { AnyObjectSchema, InferType } from 'yup'

import { FormApiProvider } from '../hooks/useFormApi'
import { ValidationSchemaProvider } from '../hooks/useValidationSchema'
import { VisibilitySchemaProvider } from '../hooks/useVisibilitySchema'
import type { ComponentMapBase, FormCustomContext } from '../types/schema-base'
import type { CancelHandler } from '../types/types'
import isValueDefined from './util/isValueDefined'

import { FormProps } from '../types/FormProps'

function _Form<
  TValidationSchema extends AnyObjectSchema,
  TVisibilitySchema extends AnyObjectSchema,
  CMap extends ComponentMapBase = ComponentMapBase,
  TContext extends FormCustomContext = FormCustomContext,
  TFieldValues extends FieldValues = InferType<TValidationSchema>,
>({
  errorMode = 'first',
  submitMode = 'allow',
  componentMap,
  validationSchema,
  visibilitySchema,
  formProps,
  onSubmit,
  onError,
  onCancel,
  children,
  formApiRef,
  formWrapper: FormWrapper = React.Fragment,
  formWrapperProps,
  parseText,
  responsive,
  debug,
  ...props
}: FormProps<TValidationSchema, TVisibilitySchema, CMap, TContext>) {
  const methods = useForm<TFieldValues>({
    criteriaMode: 'all',
    mode: 'all',
    ...props,
    resolver: yupResolver(validationSchema),
  })

  if (formApiRef && !formApiRef.current) {
    formApiRef.current = methods
  }

  const defaultValues = props.defaultValues

  const handleSubmit = useCallback<SubmitHandler<TFieldValues>>(
    (values, event) => {
      const _values = { ...values } as FieldValues

      for (const key in defaultValues) {
        if (isValueDefined((defaultValues as DefaultValues<TFieldValues>)[key]) && !isValueDefined(values[key])) {
          _values[key] = null
        }
      }

      return onSubmit(_values as TFieldValues, event)
    },
    [defaultValues, onSubmit],
  )

  if (!componentMap) {
    console.error('No component map defined')

    return <p style={{ textAlign: 'center' }}>Form Error: No component map defined</p>
  }

  return (
    <FormProvider {...methods}>
      <ValidationSchemaProvider value={validationSchema}>
        <VisibilitySchemaProvider value={visibilitySchema}>
          <FormApiProvider
            value={{
              errorMode,
              submitMode,
              componentMap,
              parseText,
              responsive,
              debug,
              // Providers don't support generics, so we need to cast here
              onCancel: onCancel as CancelHandler<FieldValues>,
            }}
          >
            <FormWrapper {...formWrapperProps}>
              <Box
                {...formProps}
                noValidate
                name="form"
                component="form"
                autoComplete="off"
                onSubmit={methods.handleSubmit(handleSubmit, onError)}
              >
                {children}
              </Box>
            </FormWrapper>
          </FormApiProvider>
        </VisibilitySchemaProvider>
      </ValidationSchemaProvider>
    </FormProvider>
  )
}

const Form = React.memo(_Form) as typeof _Form

export default Form
