import React from 'react'

import useFormSchema from '../../hooks/useFormSchema'
import SchemaFormComponent from './SchemaFormComponent'
import SchemaFieldArrayComponent from './SchemaFieldArrayComponent'
import type { FieldArraySchema, FormComponentSchema } from '../../types/schema'
import type { ArrayPath, FieldValues, Path } from 'react-hook-form'

export interface SchemaComponentRenderer<TFieldValues extends FieldValues> {
  name: Path<TFieldValues> | ArrayPath<TFieldValues>
}

const _SchemaComponentRenderer = <TFieldValues extends FieldValues>(props: SchemaComponentRenderer<TFieldValues>) => {
  const { name } = props

  const { blockIndex } = useFormSchema()

  if (!blockIndex) {
    return null
  }

  const componentSchema = blockIndex[name]

  if (!componentSchema) {
    console.error('No schema found for name: ', name)
    return null
  }

  if (componentSchema.type === 'array') {
    return (
      <SchemaFieldArrayComponent<TFieldValues>
        // Explicitly cast here because I'm pretty sure we can never resolve this type here but it doesn't matter
        {...(componentSchema as FieldArraySchema)}
        name={componentSchema.name as ArrayPath<TFieldValues>}
      />
    )
  }

  return <SchemaFormComponent {...(componentSchema as FormComponentSchema)} />
}

const SchemaComponentRenderer = React.memo(_SchemaComponentRenderer) as typeof _SchemaComponentRenderer

export default SchemaComponentRenderer
