import React, { type ComponentProps, useCallback } from 'react'
import type { DefaultValues, FieldValues, SubmitHandler } from 'react-hook-form'
import { type AnyObjectSchema } from 'yup'
import { Button } from '@mui/material'

import useParseSchema from '../hooks/useParseSchema'
import type { FormSchema } from '../types/schema'
import type { ComponentMapBase, FormCustomContext } from '../types/schema-base'
import SchemaFormComponents from './schema/SchemaFormComponents'
import isValueDefined from './util/isValueDefined'
import Form from './Form'
import { FormProps } from '../types/FormProps'
import { FormSchemaProvider } from './data/FormSchemaProvider'
import CalculatedField from '../features/calculatedFields/CalculatedField'

export type SchemaFormProps<
  TFieldValues extends FieldValues = FieldValues,
  TComponentMap extends Partial<ComponentMapBase> = ComponentMapBase,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TForm extends React.ComponentType<any> = React.ComponentType,
  TContext extends FormCustomContext = FormCustomContext,
  TSchema extends FormSchema<AnyObjectSchema, AnyObjectSchema, TComponentMap, TContext, TFieldValues> = FormSchema<
    AnyObjectSchema,
    AnyObjectSchema,
    TComponentMap,
    TContext,
    TFieldValues
  >,
> = Omit<
  FormProps<TSchema['validationSchema'], TSchema['visibilitySchema'], TComponentMap, TContext, TFieldValues>,
  'defaultValues' | 'validationSchema' | 'visibilitySchema'
> &
  Partial<Omit<ComponentProps<TForm>, 'onSubmit' | 'defaultValues' | 'validationSchema' | 'visibilitySchema'>> & {
    onSubmit: SubmitHandler<TFieldValues>
    schema: TSchema
    layout?: React.ComponentType
    FormComponent?: TForm
    // redefine defaultValues here to narrow the type
    defaultValues?: DefaultValues<TFieldValues>
    hideSubmitButton?: boolean
    ButtonComponent?: React.ComponentType<React.PropsWithChildren<unknown>>
  }

function _SchemaForm<
  TFieldValues extends FieldValues = FieldValues,
  TComponentMap extends Partial<ComponentMapBase> = ComponentMapBase,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TForm extends React.ComponentType<any> = React.ComponentType,
  TContext extends FormCustomContext = FormCustomContext,
>({
  schema,
  defaultValues,
  layout: Layout,
  FormComponent,
  onSubmit,
  hideSubmitButton,
  ButtonComponent,
  ...props
}: SchemaFormProps<
  TFieldValues,
  TComponentMap,
  TForm,
  TContext,
  FormSchema<AnyObjectSchema, AnyObjectSchema, TComponentMap, TContext, TFieldValues>
>) {
  const { formProps, blocks, blockIndex } = useParseSchema<
    AnyObjectSchema,
    AnyObjectSchema,
    TFieldValues,
    TComponentMap,
    TContext
  >(schema, defaultValues)

  const Cmp = FormComponent ?? Form

  const handleSubmit = useCallback<SubmitHandler<TFieldValues>>(
    (values, event) => {
      let _values = { ...values } as Record<keyof TFieldValues, unknown>

      if (schema.transforms) {
        _values = Object.entries(_values).reduce((acc, [key, value]: [keyof TFieldValues, unknown]) => {
          const fieldType = blockIndex[key].type

          if (isValueDefined(value) && schema.transforms && fieldType in schema.transforms) {
            acc[key] = schema.transforms[fieldType]?.(value, 'out')
          } else {
            acc[key] = value
          }

          return acc
        }, _values)
      }

      return onSubmit(_values as TFieldValues, event)
    },
    [schema.transforms, onSubmit, blockIndex],
  )

  const ButtonCmp = ButtonComponent ? (
    <ButtonComponent>Submit</ButtonComponent>
  ) : (
    <Button size="large" type="submit">
      Submit
    </Button>
  )

  return (
    <FormSchemaProvider<TContext, TFieldValues, TComponentMap> value={{ schema, blockIndex }}>
      <Cmp {...props} {...formProps} onSubmit={handleSubmit}>
        {Layout ? <Layout /> : <SchemaFormComponents<TFieldValues, TContext> components={blocks} />}

        {schema.calculatedFields &&
          Object.entries(schema.calculatedFields).map(([fieldName, config]) => (
            //@ts-expect-error object.entries for some reason returns config as unknown
            <CalculatedField {...config} name={fieldName} key={fieldName} />
          ))}

        {!FormComponent && !hideSubmitButton && ButtonCmp}
      </Cmp>
    </FormSchemaProvider>
  )
}

const SchemaForm = React.memo(_SchemaForm) as typeof _SchemaForm

export default SchemaForm
